<template lang="pug">
   v-app-bar#app-bar(absolute="" dark app="" color="secondary" flat="" height="75")
    //- menu
    v-btn.mr-3(elevation="1" fab="" small="" color="" @click="$vuetify.breakpoint.smAndDown ? setDrawer(!drawer) : $emit('input', !value)")
      v-icon(v-if="value") mdi-view-quilt
      img(v-else="" src="@/assets/images/drawer-icon.png" width="auto" height="35px" alt="")
    v-btn(v-if="$route.name === 'Contact us'" small text @click="$router.go(-1)")
      v-icon mdi-keyboard-backspace
    v-spacer
    v-toolbar-title.font-weight-light(v-text="$route.name" v-if="$route.name !== 'Home'")
    v-spacer
    v-btn(v-if="$route.name !== 'Home'"  flat text small @click="$router.replace('/contributor/')")
      v-img(contain  src="@/assets/images/icon_home.png" width="30px" height="30px" alt="" )
    v-btn(flat text small href="https://scenefinder.com")
      v-img(  contain src="@/assets/images/lupa.png" width="30px" height="30px" alt="")
    div
      //- Validar que exista el usuario notificaciones y logout
      span(v-if="user")
        v-menu(bottom="" left="" offset-y="" origin="top right" transition="scale-transition" v-model="showMenu")
          template(v-slot:activator="{ attrs, on }")
            v-btn.ml-2.hidden-md(small min-width="0" text="" v-bind="attrs" v-on="on" @click="open")
              v-badge(v-if="notifications.length > 0" color="red" overlap="" bordered="")
                template(v-slot:badge="")
                  span {{ notifications.length }}
                v-icon mdi-bell
              v-icon(v-else) mdi-bell
          v-list(:tile="false" nav="" two-line="")
            div
              //- list notifications
              span(v-if="notifications.length > 0" )
                app-bar-item(v-for="(notification, i) in notifications" :key="`item-${i}`")
                  v-list-item-content(@click="goNotifications")
                    v-list-item-title(v-text="notification.title")
                    v-list-item-subtitle(v-text="notification.description")
              //- no notifications
              span(v-else)
                app-bar-item
                  v-list-item-content(@click="goNotifications")
                    v-list-item-title(v-text="'No new notifications'")
        //- account
        v-menu(bottom="" left="" min-width="200" offset-y="" origin="top right" transition="scale-transition")
          template(v-slot:activator="{ attrs, on }")
            v-btn.ml-2.hidden-md(small min-width="0" text="" v-bind="attrs" v-on="on")
              v-icon mdi-account
          v-list(:tile="false" flat="" nav="")
            template(v-for="(p, i) in profile")
              v-divider.mb-2.mt-2(v-if="p.divider" :key="`divider-${i}`")
              app-bar-item(v-else="" :key="`item-${i}`" :to="p.to")
                v-list-item-title(@click="p.logout ? logout() : '/' " v-text="p.title")
        .custom-overlay.custom-overlay--blue(:class="$route.name === 'Home' ? 'full-mode' : 'toolbar-mode'")
          img(v-if="$route.name === 'Home'" :src="customOverlay" height="100%" width="auto")
          img.overlay2(v-if="$route.name === 'Home'" :src="customOverlay2" height="100%" width="auto")
          v-img.brand-logo(v-if="$route.name === 'Home'" :src="brandLogo" width="50%")
            img.icon-search(v-if="$route.name !== 'Home'" :src="searchIcon" alt="")
            h2.toolbar__title(v-if="$route.name !== 'Home'")
          .tag-line.display-1.text-center(v-if= "$route.name === 'Home'" ) Capture, Share, Be Rewarded
      span(else)
        v-btn.ml-2.hidden-md(v-if="!user" min-width="0" text="" @click="openDialog('register')")
          v-icon mdi-account-multiple-plus
          |Register
        v-btn.ml-2.hidden-md(v-if="!user" min-width="0" text="" @click="openDialog('login')")
          v-icon mdi-fingerprint
          |Login
</template>

<script>
  // Components
  import customOverlay from '@/assets/images/custom-overlay1.png'
  import customOverlay2 from '@/assets/images/custom-overlay2.png'
  import brandLogo from '@/assets/images/logo.png'
  import searchIcon from '@/assets/images/search-icon.png'

  import { VHover, VListItem } from 'vuetify/lib'
  import { auth } from '@/plugins/auth/firebase'
  import Cookie from 'js-cookie'
  import loaders from '@/plugins/mixins/loaders'
  // Utilities
  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },

    mixins: [loaders],

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      customOverlay: customOverlay,
      customOverlay2: customOverlay2,
      brandLogo: brandLogo,
      searchIcon: searchIcon,
      profile: [
        { title: 'Profile & Settings', to: '/contributor/profile' },
        { divider: true },
        { logout: true, title: 'Log out' },
      ],
      loginDialog: false,
      registerDialog: false,
      showMenu: false,
    }),

    computed: {
      drawer () {
        return this.$store.state.drawer.drawer
      },
      notifications () {
        return this.$store.getters['notifications/getNoRead']
      },
      user () {
        return this.$store.state.users.user
      },
    },

    created () {
      const vm = this
      if (vm.user) {
        vm.loadNoRead()
      }
    },

    methods: {
      open () {
        const vm = this
        if (vm.notifications.length > 0 && vm.showMenu) {
          // Actualizar
          vm.$store.dispatch('notifications/UpdateNoRead')
        }
      },

      goNotifications () {
        if (this.notifications.length > 0) {
          // Actualizar
          // console.log('actualizar')
          this.$store.dispatch('notifications/UpdateNoRead')
        }
        // this.$router.replace('/notifications')
      },

      openDialog (dialog) {
        this.$store.dispatch('dialogs/openDialog', dialog).then(function () {
          // console.log('dialogo seteado a : ' + dialog)
        })
      },
      async logout () {
        const vm = this
        await auth.signOut()
        await Cookie.remove('access_token')
        vm.$store.dispatch('users/clearUser').then(function () {
          vm.$router.replace('/')
        })
      },
      setDrawer (payload) {
        this.$store.dispatch('drawer/set_drawer', payload)
      },

    },
  }
</script>
<style lang="scss" scoped>
  .tag-line{
    bottom: 50px;
    right:0;
    left:0;
    margin:auto;
    position: absolute;
  }
  #app-bar{
    @media(max-width:425px){
      background-color: transparent !important;
    }
  }
</style>
